import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IUserDetailModel } from "src/app/shared/model/proseekers/user-detail.model";
import { CreateStaffFormComponent } from "../create-staff-form/create-staff-form.component";

@Component({
  selector: "app-create-staff-button",
  templateUrl: "./create-staff-button.component.html",
  styleUrls: ["./create-staff-button.component.scss"],
})
export class CreateStaffButtonComponent implements OnInit, OnChanges {
  @Input() userData: any;
  @Output() modalClosed = new EventEmitter<void>();

  constructor(protected modalService: NgbModal) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.userData != null && this.userData != undefined) {
      const modalRef = this.modalService.open(CreateStaffFormComponent, {
        size: "lg",
        backdrop: "static",
      });
      modalRef.componentInstance.userData = this.userData;
      this.modalClosed.emit();
    }
  }
  ngOnInit(): void {}
  createStaffFormModal() {
    const modalRef = this.modalService.open(CreateStaffFormComponent, {
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.userData = this.userData;
    this.modalClosed.emit();
  }
}
