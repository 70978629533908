import { KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { KeycloakConfig } from 'keycloak-js';
import { environment } from '../../../environments/environment';

export function initializer(keycloak: KeycloakService): () => Promise<boolean> {
  const options: KeycloakOptions = {
    config: environment.keycloak,
    initOptions: {
      onLoad: 'login-required',
      // silentCheckSsoRedirectUri:
      //   window.location.origin + '/assets/silent-check-sso.html',
      checkLoginIframe: true,
    },
    loadUserProfileAtStartUp: false,
    bearerExcludedUrls: ['/assets', '/clients/public'],
    shouldAddToken: (request) => {
      const { method, url } = request;

      const isGetRequest = 'GET' === method.toUpperCase();
      const acceptablePaths = ['/assets', '/clients/public'];
      const isAcceptablePathMatch = acceptablePaths.some((path) =>
        url.includes(path)
      );
      return !(isGetRequest && isAcceptablePathMatch);
    },
  };

  return () => keycloak.init(options);
}
