/**
 * Here you can add the configuration related to keycloak
 * So we can use this common config for diffrent environment
 */
import { KeycloakConfig } from "keycloak-js";

const keycloakConfig: KeycloakConfig = {
  url: "http://keycloak-dev.hqdevelops.com:8080/",
  realm: "om",
  clientId: "om-web-app",
};

export default keycloakConfig;
