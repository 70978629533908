import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { ActivatedRoute, Data, ParamMap, Router } from "@angular/router";
import { Observable, combineLatest } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";
import { IUserUserResponseModel } from "src/app/shared/model/proseekers/users.model";
import { HttpArrayRespnseType, UserService } from "../user.service";
import {
  ITEMS_PER_PAGE,
  PAGE_HEADER,
  TOTAL_COUNT_RESPONSE_HEADER,
} from "../../../../../config/pagination.constants";
import {
  ASC,
  DESC,
  SORT,
  DEFAULT_SORT_DATA,
  ITEM_DELETED_EVENT,
} from "../../../../../config/navigation.constants";
import { HttpHeaders } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeleteUserComponent } from "../delete-user/delete-user.component";
import { ToastService } from "src/app/shared/components/toast/toast.service";

import { ViewUserPopupComponent } from "../view-user-popup/view-user-popup.component";
import { DeleteDialogComponent } from "src/app/shared/components/delete-dialog/delete-dialog.component";

@Component({
  selector: "app-list-user",
  templateUrl: "./list-user.component.html",
  styleUrls: ["./list-user.component.scss"],
})
export class ListUserComponent implements OnInit {
  @Input() data: any[];
  @Input() userViewData: any;
  @Output() modalClosed = new EventEmitter<void>();
  userMembers?: IUserUserResponseModel[];
  isLoading = false;
  userData: any[];
  userUserData: any = null;
  title = "User Management";
  tableHeader = [
    {
      sortable: "userDetail.firstName",
      title: "First Name",
      nested: true,
    },
    {
      sortable: "userDetail.lastName",
      title: "Last Name",
      nested: true,
      lookup: null,
    },
    {
      sortable: "userDetail.username",
      title: "User Name",
      nested: true,
      lookup: null,
    },
    {
      sortable: "userDetail.profileStatus",
      title: "Profile Status",
      nested: true,
      lookup: null,
    },
    {
      sortable: "userDetail.gender",
      title: "Gender",
      nested: true,
      lookup: null,
    },
    {
      sortable: "userDetail.dateOfBirth",
      title: "Date of Birth",
      nested: true,
      lookup: null,
    },
    {
      sortable: "userDetail.userType",
      title: "User Type",
      lookup: null,
      nested: true,
    },
  ];

  predicate = "id";
  ascending = true;

  itemsPerPage = ITEMS_PER_PAGE;
  totalItems = 0;
  page = 1;

  constructor(
    private userService: UserService,
    protected activatedRoute: ActivatedRoute,
    public router: Router,
    protected modalService: NgbModal,
    private toastrService: ToastService
  ) {}

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.loadFromBackendWithRouteInformations().subscribe({
      next: (res: HttpArrayRespnseType) => {
        this.onResponseSuccess(res);
      },
    });
  }
  handleIconClickEvent(eventData: { eventType: string; data: any }) {
    if (eventData.eventType === "DELETE") {
      const modalRef = this.modalService.open(DeleteDialogComponent, {
        backdrop: "static",
      });
      modalRef.componentInstance.data = eventData.data;
      modalRef.componentInstance.confirmDelete.subscribe(
        (confirmation: any) => {
          if (confirmation) {
            this.userService.deleteUserData(eventData.data.id).subscribe(() => {
              this.load();
            });
          }
        }
      );
    }
    if (eventData.eventType == "EDIT") {
      this.userUserData = eventData.data;
    }
    if (eventData.eventType == "VIEW") {
      const modalRef = this.modalService.open(ViewUserPopupComponent, {
        backdrop: "static",
      });
      modalRef.componentInstance.data = this.data;
      this.modalClosed.emit();
    }
  }
  clearData() {
    this.userUserData = null;
  }

  protected onResponseSuccess(response: HttpArrayRespnseType): void {
    this.fillComponentAttributesFromResponseHeader(response.headers);
    const dataFromBody = this.fillComponentAttributesFromResponseBody(
      response.body
    );
    this.userMembers = dataFromBody;
    this.userData = dataFromBody;
  }

  protected fillComponentAttributesFromResponseHeader(
    headers: HttpHeaders
  ): void {
    this.totalItems = Number(headers.get(TOTAL_COUNT_RESPONSE_HEADER));
  }

  protected fillComponentAttributesFromResponseBody(
    data: IUserUserResponseModel[] | null
  ): IUserUserResponseModel[] {
    return data ?? [];
  }

  protected loadFromBackendWithRouteInformations(): Observable<HttpArrayRespnseType> {
    return combineLatest([
      this.activatedRoute.queryParamMap,
      this.activatedRoute.data,
    ]).pipe(
      tap(([params, data]) =>
        this.fillComponentAttributeFromRoute(params, data)
      ),
      switchMap(() =>
        this.queryBackend(this.page, this.predicate, this.ascending)
      )
    );
  }

  protected fillComponentAttributeFromRoute(
    params: ParamMap,
    data: Data
  ): void {
    const page = params.get(PAGE_HEADER);
    this.page = +(page ?? 1);
    const sort = (params.get(SORT) ?? data[DEFAULT_SORT_DATA]).split(",");
    this.predicate = sort[0];
    this.ascending = sort[1] === ASC;
  }

  protected queryBackend(
    page?: number,
    predicate?: string,
    ascending?: boolean
  ): Observable<HttpArrayRespnseType> {
    this.isLoading = true;
    const pageToLoad: number = page ?? 1;
    const queryObject = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      eagerload: true,
      sort: this.getSortQueryParam(predicate, ascending),
    };
    console.log("inside queryBackend()");
    return this.userService
      .query(queryObject)
      .pipe(tap(() => (this.isLoading = false)));
  }

  protected getSortQueryParam(
    predicate = this.predicate,
    ascending = this.ascending
  ): string[] {
    const ascendingQueryParam = ascending ? ASC : DESC;
    if (predicate === "") {
      return [];
    } else {
      return [predicate + "," + ascendingQueryParam];
    }
  }

  delete(userMember: IUserUserResponseModel): void {
    console.log("inside delete");
    const modalRef = this.modalService.open(DeleteUserComponent, {
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.userMember = userMember;
    modalRef.closed
      .pipe(
        filter((reason) => reason === ITEM_DELETED_EVENT),
        switchMap(() => this.loadFromBackendWithRouteInformations())
      )
      .subscribe({
        next: (res: HttpArrayRespnseType) => {
          this.onResponseSuccess(res);
          this.toastrService.success(
            "User member with ID {" +
              userMember.id +
              "} is deleted successfully.",
            ""
          );
        },
      });
  }

  edit(): void {}

  navigateToWithComponentValues(): void {
    this.handleNavigation(this.page, this.predicate, this.ascending);
  }

  protected handleNavigation(
    page = this.page,
    predicate?: string,
    ascending?: boolean
  ): void {
    console.log("inside handleNavigation()");
    const queryParamsObj = {
      page,
      size: this.itemsPerPage,
      sort: this.getSortQueryParam(predicate, ascending),
    };

    console.log(this.activatedRoute);
    this.router.navigate(["./"], {
      relativeTo: this.activatedRoute,
      queryParams: queryParamsObj,
    });
  }

  navigateToPage(page = this.page): void {
    this.handleNavigation(page, this.predicate, this.ascending);
  }
}
