<div
  class="modal-content"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-header">
    <label class="modal-title">
      {{ data.userDetail.username }}
    </label>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <label class="col-modal-label">First Name:</label>
      <span>{{ data.userDetail.firstName }}</span>
    </div>
    <div>
      <label class="col-modal-label">Last Name:</label>
      <span>{{ data.userDetail.lastName }}</span>
    </div>
    <div>
      <label class="col-modal-label">Username:</label>
      <span>{{ data.userDetail.username }}</span>
    </div>
    <div>
      <label class="col-modal-label">Gender:</label>
      <span>{{ data.userDetail.gender }}</span>
    </div>
    <div>
      <label class="col-modal-label">User Type:</label>
      <span>{{ data.userDetail.userType }}</span>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        btn-id="close"
        (click)="close()"
      >
        Close
      </button>
    </div>
  </div>
</div>
