import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-view-user-popup",
  templateUrl: "./view-user-popup.component.html",
  styleUrls: ["./view-user-popup.component.scss"],
})
export class ViewUserPopupComponent implements OnInit {
  @Input() data: any;

  constructor(protected modalService: NgbModal) {}

  ngOnInit(): void {}
  close() {
    this.modalService.dismissAll();
  }
}
