import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ILookupCodeToTextModel,
  ILookupDataModel,
} from '../model/proseeker/lookup/lookup.model';
import {
  EntityResponseType,
  LookupService,
} from '../services/lookup/lookup.service';
import { LookupMap } from './lookup-map';

@Pipe({
  name: 'lookupCodeToText',
})
export class FormatMediumDatePipe implements PipeTransform {
  constructor(protected lookupService: LookupService) {}

  transform(value: string, lookupName: string): string {
    return this.getLookupData(lookupName, value);

    // debugger;
    // const lookupId = this.mapLookupNameToId(lookupName);
    // console.log(LookupMap.getLookupMap().get(lookupName).lookupData);
    // console.log(LookupMap.getLookupNamesMap());
    // if (lookupId === -1) return 'invalid';
    // return this.getText(lookupName, lookupId, value);
  }

  // mapLookupNameToId(lookupName: string) {
  //   debugger;
  //   if (this.isLookupFoundInMap(lookupName)) {
  //     return [...LookupMap.getLookupNamesMap().entries()]
  //       .filter(({ 1: v }) => v === lookupName)
  //       .map(([k]) => k)[0];
  //   } else {
  //     this.lookupService.getAllLookupNames().subscribe((data) => {
  //       data.body.forEach((lookup) => {
  //         LookupMap.getLookupNamesMap().set(lookup.id, lookup.name);
  //         this.addLookupNameToMap(lookup.name);
  //       });
  //       if (this.isLookupFoundInMap(lookupName)) {
  //         return [...LookupMap.getLookupNamesMap().entries()]
  //           .filter(({ 1: v }) => v === lookupName)
  //           .map(([k]) => k)[0];
  //       }
  //     });
  //   }
  //   // return -1;
  // }

  // isLookupFoundInMap(lookupName: string): boolean {
  //   return [...LookupMap.getLookupNamesMap().values()].includes(lookupName);
  // }

  // getText(lookupName: string, lookupId: number, value: string): string {
  //   if (LookupMap.getLookupMap().has(lookupName)) {
  //     let lookupText = this.getLookupData(lookupName, value);
  //     if (lookupText === 'invalid from getLookupData()') {
  //       this.refreshLookupMap(lookupName, lookupId, value);
  //       // lookupText = this.getLookupData(lookupName, value);
  //     }
  //     return lookupText;
  //   } else {
  //     // this.addLookupNameToMap(lookupName);
  //     // this.getText(lookupName, lookupId, value);
  //     return 'invalid from getText()';
  //   }
  // }
  // addLookupNameToMap(lookupName: string) {
  //   LookupMap.addLookupToMap(lookupName, { lookupData: [] });
  // }

  getLookupData(lookupName: string, value: string) {
    // console.log(LookupMap.getLookupMap());
    let lookupData = LookupMap.getLookupMap()
      .get(lookupName)
      .lookupData.filter((lookupData) => lookupData.code === value);
    if (lookupData.length == 1) {
      return lookupData.map((data) => {
        return data.text;
      })[0];
    } else {
      return '';
    }
  }

  // refreshLookupMap(lookupName: string, lookupId: number, value: string): void {
  //   this.lookupService.getLookup(lookupId).subscribe({
  //     next: (data) => {
  //       LookupMap.addLookupToMap(lookupName, {
  //         lookupData: data.body.lookupData,
  //       });
  //       this.getText(lookupName, lookupId, value);
  //     },
  //   });
  // }
}
