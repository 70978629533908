// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import keycloakConfig from "./keycloak.config";

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCJi0cQ866lzPR8hYJUs77JUmD4DBpswl8",
    authDomain: "angular-deomo.firebaseapp.com",
    databaseURL: "https://angular-deomo.firebaseio.com",
    projectId: "angular-deomo",
    storageBucket: "angular-deomo.appspot.com",
    messagingSenderId: "175907956497",
  },
  keycloak: keycloakConfig,
  BACKEND_SERVICES: {
    OM_USER_SERVICE: {
      HOST: "http://om-user-service-dev.hqdevelops.com",
      PORT: "8081",
    },
    OM_STATIC_DATA_SERVICE: {
      HOST: "http://om-static-data-service-dev.hqdevelops.com",
      PORT: "8082",
    },
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
