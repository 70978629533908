import {
  Component,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from "@angular/core";
import { Observable } from "rxjs";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from "src/app/shared/services/table.service";

@Component({
  selector: "basic-table",
  templateUrl: "./basic.component.html",
  styleUrls: ["./basic.component.scss"],
})
export class BasicNgxDatatableComponent implements OnChanges {
  @Input() data: any[];
  @Input() headers: any[];
  @Input() title: any[];

  public selected = [];

  public tableItem$: Observable<any[]>;
  public searchText;
  total$: Observable<number>;

  constructor(public service: TableService) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.service.setUserData(this.data);
    this.tableItem$ = this.service.tableItem$;
    this.total$ = this.service.total$;
  }

  @ViewChildren(NgbdSortableHeader) tableHeaders: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.tableHeaders.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  public getValue(row, col) {
    if (col.isNested) {
      let innerObjetcs = col.sortable.split(".");
      let finalVal = row;
      for (let object in innerObjetcs) {
        finalVal = finalVal[innerObjetcs[object]];
      }
      return finalVal;
    } else {
      return row[col.sortable];
    }
  }

  public onSelect(selected) {
    this.service.deleteSingleData(selected);
  }

  ngOnInit() {
    this.service.setUserData(this.data);
    this.tableItem$ = this.service.tableItem$;
    this.total$ = this.service.total$;
  }
}
