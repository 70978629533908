<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-xl-12">
      <jhi-alert-error></jhi-alert-error>
      <div class="row">
        <div class="col-sm-12">
          <form
            class="theme-form"
            novalidate
            [formGroup]="createStaffForm"
            (ngSubmit)="submit()"
          >
            <div class="card">
              <div class="card-header">
                <h5 *ngIf="userData == null">Add new staff member</h5>
                <h5 *ngIf="userData !== null">
                  Update - {{ userData.userDetail.username }}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  (click)="cancel()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Title</label>
                  <div class="col-sm-9">
                    <input
                      class="form-control"
                      formControlName="title"
                      type="text"
                      data-cy="myTitle"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label"
                    >First Name
                    <span class="required-asterisk">*</span>
                  </label>
                  <div class="col-sm-9">
                    <input
                      class="form-control"
                      type="text"
                      data-cy="firstName"
                      formControlName="firstName"
                      required
                    />

                    <div
                      *ngIf="
                        createStaffForm.controls.firstName.touched &&
                        createStaffForm.controls.firstName.errors?.required
                      "
                      class="text text-danger"
                      data-cy="firstName-error"
                    >
                      First Name is required.
                    </div>
                    <div
                      *ngIf="
                        createStaffForm.controls.firstName.touched &&
                        createStaffForm.controls.firstName.errors?.pattern
                      "
                      class="text text-danger"
                      data-cy="firstName-alphabates-error"
                    >
                      Only alphabets are allowed.
                    </div>
                    <div
                      *ngIf="
                        createStaffForm.controls.firstName.touched &&
                        createStaffForm.controls.firstName.errors?.minlength
                      "
                      class="text text-danger"
                      data-cy="firstName-short-error"
                    >
                      Length should be at least 3 alphabets
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label"
                    >Last Name
                    <span class="required-asterisk">*</span>
                  </label>
                  <div class="col-sm-9">
                    <input
                      class="form-control"
                      type="text"
                      data-cy="lastName"
                      [formControl]="createStaffForm.controls['lastName']"
                      required
                    />

                    <div
                      *ngIf="
                        createStaffForm.controls.lastName.touched &&
                        createStaffForm.controls.lastName.errors?.required
                      "
                      class="text text-danger"
                      data-cy="lastName-error"
                    >
                      Last Name is required.
                    </div>
                    <div
                      *ngIf="
                        createStaffForm.controls.lastName.touched &&
                        createStaffForm.controls.lastName.errors?.pattern
                      "
                      class="text text-danger"
                      data-cy="lastName-alphabates-error"
                    >
                      Only alphabets are allowed
                    </div>
                    <div
                      *ngIf="
                        createStaffForm.controls.lastName.touched &&
                        createStaffForm.controls.lastName.errors?.minlength
                      "
                      class="text text-danger"
                      data-cy="lastName-short-error"
                    >
                      Length should be at least 3 alphabets
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label"
                    >Username
                    <span class="required-asterisk">*</span>
                  </label>
                  <div class="col-sm-9">
                    <input
                      class="form-control"
                      type="text"
                      data-cy="userName"
                      [formControl]="createStaffForm.controls['userName']"
                      required
                    />

                    <div
                      *ngIf="
                        createStaffForm.controls.userName.touched &&
                        createStaffForm.controls.userName.errors?.required
                      "
                      class="text text-danger"
                      data-cy="Username-error"
                    >
                      Username is required.
                    </div>
                    <div
                      *ngIf="
                        createStaffForm.controls.userName.touched &&
                        createStaffForm.controls.userName.errors?.pattern
                      "
                      class="text text-danger"
                      data-cy="Username-alphabates-error"
                    >
                      Alphabets and numbers are allowed.
                    </div>
                    <div
                      *ngIf="
                        createStaffForm.controls.userName.touched &&
                        createStaffForm.controls.userName.errors?.minlength
                      "
                      class="text text-danger"
                      data-cy="Username-short-error"
                    >
                      Length should be at least 3 alphabets.
                    </div>
                    <div
                      *ngIf="
                        createStaffForm.controls.userName.touched &&
                        createStaffForm.controls.userName.errors?.maxlength
                      "
                      class="text text-danger"
                      data-cy="Username-long-error"
                    >
                      Username length should be less than 10.
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label"
                    >Password
                    <span class="required-asterisk">*</span>
                  </label>
                  <div class="col-sm-9">
                    <input
                      class="form-control"
                      type="password"
                      data-cy="password"
                      [formControl]="createStaffForm.controls['password']"
                      required
                    />

                    <div
                      *ngIf="
                        createStaffForm.controls.password.touched &&
                        createStaffForm.controls.password.errors?.required
                      "
                      class="text text-danger"
                      data-cy="password-error"
                    >
                      Password is required.
                    </div>
                  </div>
                </div>

                <fieldset class="form-group">
                  <div class="row">
                    <label class="col-form-label col-sm-3 pt-0" data-cy="gender"
                      >Gender
                      <span class="required-asterisk">*</span>
                    </label>
                    <div class="col-sm-9">
                      <span class="radio radio-primary ms-2">
                        <input
                          id="gender_male"
                          type="radio"
                          name="gender"
                          data-cy="male"
                          value="0"
                          checked
                          [formControl]="createStaffForm.controls['gender']"
                        />
                        <label for="gender_male" class="m-b-10">Male</label>
                      </span>
                      <span class="radio radio-primary ms-2">
                        <input
                          id="gender_female"
                          type="radio"
                          name="gender"
                          data-cy="female"
                          value="1"
                          [formControl]="createStaffForm.controls['gender']"
                        />
                        <label for="gender_female" class="m-b-10">Female</label>
                      </span>
                      <span class="radio radio-primary ms-2">
                        <input
                          id="gender_other"
                          type="radio"
                          name="gender"
                          data-cy="other"
                          value="2"
                          [formControl]="createStaffForm.controls['gender']"
                        />
                        <label for="gender_other" class="m-b-10">Other</label>
                      </span>
                      <div
                        *ngIf="
                          createStaffForm.controls.gender.touched &&
                          createStaffForm.controls.gender.errors?.required
                        "
                        class="text text-danger"
                        data-cy="gender-error"
                      >
                        Gender is required.
                      </div>
                    </div>
                  </div>
                </fieldset>

                <fieldset class="form-group">
                  <div class="row">
                    <label
                      class="col-form-label col-sm-3 pt-0"
                      data-cy="staff_type"
                      >Staff Member Type
                      <span class="required-asterisk">*</span>
                    </label>
                    <div class="col-sm-9">
                      <span class="radio radio-primary ms-2">
                        <input
                          id="staff_type_fi"
                          type="radio"
                          name="staff_type"
                          data-cy="finance_manager"
                          value="0"
                          [formControl]="createStaffForm.controls['staffType']"
                        />
                        <label for="staff_type_fi" class="m-b-10"
                          >Finance Manager</label
                        >
                      </span>
                      <span class="radio radio-primary ms-2">
                        <input
                          id="staff_type_su"
                          type="radio"
                          name="staff_type"
                          data-cy="support_manager"
                          value="1"
                          [formControl]="createStaffForm.controls['staffType']"
                        />
                        <label for="staff_type_su" class="m-b-10"
                          >Support Manager</label
                        >
                      </span>
                      <div
                        *ngIf="
                          createStaffForm.controls.staffType.touched &&
                          createStaffForm.controls.staffType.errors?.required
                        "
                        class="text text-danger"
                        data-cy="staff_type-error"
                      >
                        Staff type is required.
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="card-footer">
                <button
                  class="btn btn-secondary"
                  btn-id="cancel"
                  (click)="cancel()"
                >
                  Cancel
                </button>
                <button
                  class="btn btn-secondary me-1"
                  btn-id="submit"
                  type="submit"
                >
                  {{ userData == null ? "Submit" : "Update" }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
