import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { LoaderComponent } from "./components/loader/loader.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ContentLayoutComponent } from "./components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./components/layout/full-layout/full-layout.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { RightSidebarComponent } from "./components/right-sidebar/right-sidebar.component";
import { BookmarkComponent } from "./components/bookmark/bookmark.component";
import { CalendarModule} from 'primeng/calendar';
import { TranslateModule } from "@ngx-translate/core";
import { CustomizerComponent } from "./components/customizer/customizer.component";
import { DragulaModule } from "ng2-dragula";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { GalleryModule } from "@ks89/angular-modal-gallery";
import { AlertComponent } from "./components/alert/alert.component";
import { AlertErrorComponent } from "./components/alert/alert-error.component";
import "hammerjs";
import "mousetrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ItemCountComponent } from "./pagination/item-count.component";

// services
import { NavService } from "./services/nav.service";
import { ChatService } from "./services/chat.service";
import { CustomizerService } from "./services/customizer.service";
import { TableService } from "./services/table.service";
// Directives
import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { NgbdSortableHeader } from "./directives/NgbdSortableHeader";
import { SortDirective } from "./sort/sort.directive";
import { SortByDirective } from "./sort/sort-by.directive";
import { LookupCodeToTextDirective } from "./lookup/lookup-code-to-text.directive";
import { FormatMediumDatePipe } from "./lookup/lookup-code-to-text.pipe";
import { TablesModule } from "../components/tables/tables.module";

import { DatatableComponent } from "src/app/shared/components/table/datatable.component";
import { WidgetsModule } from "../components/widgets/widgets.module";

@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BookmarkComponent,
    RightSidebarComponent,
    ContentLayoutComponent,
    FullLayoutComponent,
    FeatherIconsComponent,
    ToggleFullscreenDirective,
    NgbdSortableHeader,
    BreadcrumbComponent,
    CustomizerComponent,
    AlertErrorComponent,
    AlertComponent,
    SortDirective,
    SortByDirective,
    LookupCodeToTextDirective,
    ItemCountComponent,
    // Custom pipes
    FormatMediumDatePipe,
    DatatableComponent,
  ],
  imports: [
    CommonModule,
    CalendarModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    DragulaModule.forRoot(),
    NgbModule,
    GalleryModule,
  ],
  exports: [
    LoaderComponent,
    FeatherIconsComponent,
    TranslateModule,
    NgbdSortableHeader,
    AlertErrorComponent,
    AlertComponent,
    SortDirective,
    SortByDirective,
    LookupCodeToTextDirective,
    FontAwesomeModule,
    ItemCountComponent,
    TablesModule,
    // Custom pipes
    FormatMediumDatePipe,
    DatatableComponent,
    
  ],
  providers: [NavService, ChatService, CustomizerService, TableService],
})
export class SharedModule {}
