import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ITEM_DELETED_EVENT } from "src/app/config/navigation.constants";
import { IUserUserResponseModel } from "src/app/shared/model/proseekers/users.model";
import { UserService } from "../user.service";

@Component({
  selector: "app-delete-user",
  templateUrl: "./delete-user.component.html",
  styleUrls: ["./delete-user.component.scss"],
})
export class DeleteUserComponent {
  userMember: IUserUserResponseModel;

  constructor(
    protected userService: UserService,
    protected activeModal: NgbActiveModal
  ) {}

  cancel(): void {
    this.activeModal.dismiss();
  }

  confirmDelete(id: number): void {
    this.userService.deleteUserData(id).subscribe(() => {
      this.activeModal.close(ITEM_DELETED_EVENT);
    });
  }
}
