import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-view-staff",
  templateUrl: "./view-staff.component.html",
  styleUrls: ["./view-staff.component.scss"],
})
export class ViewStaffComponent implements OnInit {
  @Input() data: any;

  constructor(protected modalService: NgbModal) {}

  ngOnInit() {
    console.log("data is ", this.data);
  }

  close() {
    this.modalService.dismissAll();
  }
}
