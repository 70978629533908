import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { UserInfoModel } from '../model/user.info.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userInfo: UserInfoModel;
  constructor(private keycloakService: KeycloakService) {}

  public getLoggedUser(): KeycloakTokenParsed | undefined {
    try {
      const userDetails: KeycloakTokenParsed | undefined =
        this.keycloakService.getKeycloakInstance().idTokenParsed;
      return userDetails;
    } catch (e) {
      console.error('Exception', e);
      return undefined;
    }
  }

  public isLoggedIn(): Promise<boolean> {
    return this.keycloakService.isLoggedIn();
  }

  public loadUserProfile(): Promise<KeycloakProfile> {
    return this.keycloakService.loadUserProfile();
  }

  public login(): void {
    this.keycloakService.login();
  }

  public logout(): void {
    this.keycloakService.logout(window.location.origin);
  }

  public redirectToProfile(): void {
    this.keycloakService.getKeycloakInstance().accountManagement();
  }

  public getRoles(): string[] {
    return this.keycloakService.getUserRoles();
  }

  public async getUserInfo() {
    await this.keycloakService
      .getKeycloakInstance()
      .loadUserInfo()
      .then((response) => {
        this.userInfo = {
          firstName: response['given_name'],
          lastName: response['family_name'],
          fullName: response['name'],
          userName: response['preferred_username'],
        };
      });
    return this.userInfo;
  }
}
