import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbDatepickerConfig } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { IUserDetailModel } from "src/app/shared/model/proseekers/user-detail.model";
import { AlertService } from "src/app/shared/services/alert/alert.service";
import { ICreateUserUserRequestModel } from "src/app/shared/model/proseekers/users.model";
import { HttpRespnseType, UserService } from "../user.service";
import { ToastService } from "../../../../../shared/components/toast/toast.service";
import {
  Lookup_UserType,
  Lookup_ProfileStatus,
} from "../../../../../shared/enums/Lookups";
import * as moment from "moment";

@Component({
  selector: "app-create-User-form",
  templateUrl: "./create-user-form.component.html",
  styleUrls: ["./create-user-form.component.scss"],
})
export class CreateUserFormComponent implements OnInit {
  createUserForm: FormGroup;
  user: any;

  constructor(
    protected activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private UserService: UserService,
    private toastrService: ToastService,
    private alertService: AlertService,
    private config: NgbDatepickerConfig
  ) {}

  ngOnInit(): void {
    this.createUserForm = this.fb.group({
      firstName: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z][a-zA-Z ]+[a-zA-Z]$"),
          Validators.minLength(3),
        ],
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z][a-zA-Z ]+[a-zA-Z]$"),
          Validators.minLength(3),
        ],
      ],
      userName: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9][a-zA-Z0-9 ]*[a-zA-Z0-9]$"),
          Validators.minLength(3),
          Validators.maxLength(10),
        ],
      ],
      profileStatus: ["", [Validators.required]],
      gender: ["", Validators.required],
      dateOfBirth: ["", Validators.required],
      userType: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  cancel(): void {
    this.activeModal.close();
  }
  submit() {
    if (this.createUserForm.invalid) {
      return this.createUserForm.markAllAsTouched();
    }

    const userDetails: IUserDetailModel = {
      firstName: this.createUserForm.controls["firstName"].getRawValue(),
      lastName: this.createUserForm.controls["lastName"].getRawValue(),
      gender: this.createUserForm.controls["gender"].getRawValue(),
      userName: this.createUserForm.controls["userName"].getRawValue(),
      dateOfBirth: moment(
        this.createUserForm.controls["dateOfBirth"].getRawValue()
      )
        .format("YYYY-MM-DD")
        .toString(),
      userType: parseInt(
        this.createUserForm.controls["userType"].getRawValue()
      ),
      profileStatus: parseInt(
        this.createUserForm.controls["profileStatus"].getRawValue()
      ),
    };
    var createRequest: ICreateUserUserRequestModel = {
      userDetail: userDetails,
      password: this.createUserForm.controls["password"].getRawValue(),
    };

    this.subscribeToSaveResponse(this.UserService.create(createRequest));

    // this.activeModal.close();
  }

  subscribeToSaveResponse(result: Observable<HttpRespnseType>): void {
    result.subscribe({
      next: () => this.onSaveSuccess(),
      error: () => this.onSaveError(),
    });
  }

  protected onSaveSuccess(): void {
    this.activeModal.close();
    this.toastrService.success("", "User member added successfully");
  }

  protected onSaveError(): void {
    // Api for inheritance.
  }
}
