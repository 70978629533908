import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApplicationConfigService } from "../../../../core/config/application-config.service";
import {
  CreateUserUserRequestModel,
  IUserUserResponseModel,
} from "src/app/shared/model/proseekers/users.model";

import { createRequestOption } from "../../../../core/request/request-util";

export type HttpRespnseType = HttpResponse<IUserUserResponseModel>;
export type HttpArrayRespnseType = HttpResponse<IUserUserResponseModel[]>;

@Injectable({
  providedIn: "root",
})
export class UserService {
  getUsers(): Observable<any[]> {
    return this.http.get<any[]>(""); // Modify the endpoint URL to your specific API endpoint
  }
  constructor(
    protected http: HttpClient,
    protected applicationConfigService: ApplicationConfigService
  ) {}

  protected resourceUrl =
    this.applicationConfigService.getUserServiceEndpoint("api/providers");

  create(request: CreateUserUserRequestModel): Observable<HttpRespnseType> {
    return this.http.post<IUserUserResponseModel>(this.resourceUrl, request, {
      observe: "response",
    });
  }

  query(req?: any): Observable<HttpArrayRespnseType> {
    const options = createRequestOption(req);
    return this.http.get<IUserUserResponseModel[]>(this.resourceUrl, {
      params: req,
      observe: "response",
    });
    // .pipe(map((res) => this.convertResponseArrayFromServer(res)));
  }

  deleteUserData(id: number): Observable<HttpRespnseType> {
    const resourceUrl = `${this.resourceUrl}/${id}`;
    return this.http.delete<IUserUserResponseModel>(resourceUrl, {
      observe: "response",
    });
  }

  // protected convertResponseArrayFromServer(res: HttpResponse<IStaffUserResponseModel[]>): HttpResponse<IStaffUserResponseModel[]> {
  //   return res.clone({
  //     body: res.body ? res.body.map(item => this.convertDateFromServer(item)) : null,
  //   });
  // }

  // protected convertDateFromServer(res: IStaffUserResponseModel): IStaffUserResponseModel {
  //   return {
  //     ...res,
  //     userDetail res.userDetail.dateOfBirth ? dayjs(res.userDetail.dateOfBirth) : undefined,
  //   };
  // }
}
