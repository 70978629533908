<div class="card">
  <app-create-staff-button
    [userData]="staffUserData"
    (modalClosed)="clearData()"
  ></app-create-staff-button>
  <aq-table
    [data]="staffData"
    [headers]="tableHeader"
    [title]="title"
    (iconClick)="handleIconClickEvent($event)"
  ></aq-table>
</div>
