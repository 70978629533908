import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApplicationConfigService } from "../../../core/config/application-config.service";
import {
  CreateStaffUserRequestModel,
  IStaffUserResponseModel,
} from "src/app/shared/model/proseeker/staff.model";

import { DeleteStaffComponent } from "./delete-staff/delete-staff.component";

import { createRequestOption } from "../../../core/request/request-util";
import { id } from "@swimlane/ngx-charts";

export type HttpRespnseType = HttpResponse<IStaffUserResponseModel>;
export type HttpArrayRespnseType = HttpResponse<IStaffUserResponseModel[]>;

@Injectable({
  providedIn: "root",
})
export class StaffService {
  constructor(
    protected http: HttpClient,
    protected applicationConfigService: ApplicationConfigService
  ) {}

  protected resourceUrl =
    this.applicationConfigService.getUserServiceEndpoint("api/staffs");

  create(
    update: boolean,
    request: CreateStaffUserRequestModel
  ): Observable<HttpRespnseType> {
    if (update) {
      return this.http.put<IStaffUserResponseModel>(this.resourceUrl, request, {
        observe: "response",
      });
    } else {
      return this.http.post<IStaffUserResponseModel>(
        this.resourceUrl,
        request,
        {
          observe: "response",
        }
      );
    }
  }
  query(req?: any): Observable<HttpArrayRespnseType> {
    return this.http.get<IStaffUserResponseModel[]>(this.resourceUrl, {
      params: req,
      observe: "response",
    });
  }

  deleteData(id: number): Observable<HttpRespnseType> {
    const resourceUrl = `${this.resourceUrl}/${id}`;
    return this.http.delete<IStaffUserResponseModel>(resourceUrl, {
      observe: "response",
    });
  }

  // protected convertResponseArrayFromServer(res: HttpResponse<IStaffUserResponseModel[]>): HttpResponse<IStaffUserResponseModel[]> {
  //   return res.clone({
  //     body: res.body ? res.body.map(item => this.convertDateFromServer(item)) : null,
  //   });
  // }

  // protected convertDateFromServer(res: IStaffUserResponseModel): IStaffUserResponseModel {
  //   return {
  //     ...res,
  //     userDetail res.userDetail.dateOfBirth ? dayjs(res.userDetail.dateOfBirth) : undefined,
  //   };
  // }
}
