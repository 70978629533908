import { HttpClient } from "@angular/common/http";
import {
  Component,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChildren,
  EventEmitter,
  Output,
} from "@angular/core";
import { Observable } from "rxjs";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/shared/directives/NgbdSortableHeader";
import { FormatMediumDatePipe } from "src/app/shared/lookup/lookup-code-to-text.pipe";
import { TableService } from "src/app/shared/services/table.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "aq-table",
  templateUrl: "./datatable.component.html",
  styleUrls: ["./datatable.component.scss"],
  providers: [FormatMediumDatePipe],
})
export class DatatableComponent implements OnChanges {
  @Input() data: any[];
  @Input() headers: any[];
  @Input() title: any[];

  @Output() iconClick: EventEmitter<{ eventType: string; data: any }> =
    new EventEmitter<{ eventType: string; data: any }>();

  public tableItem$: Observable<any[]>;
  public searchText;
  total$: Observable<number>;
  showEditForm: boolean;

  constructor(
    public service: TableService,
    private lookupPipe: FormatMediumDatePipe,
    protected modalService: NgbModal
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.service.setUserData(this.data);
    this.tableItem$ = this.service.tableItem$;
    this.total$ = this.service.total$;
  }

  @ViewChildren(NgbdSortableHeader) tableHeaders: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.tableHeaders.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  public getValue(row, col) {
    if (col.nested) {
      let innerObjetcs = col.sortable.split(".");
      let finalVal = row;
      for (let object in innerObjetcs) {
        finalVal = finalVal[innerObjetcs[object]];
      }
      return finalVal; //return this.lookupPipe.transform(finalVal, col.lookup);
    } else {
      return row[col.sortable]; //return this.lookupPipe.transform(row[col.sortable], col.lookup);
    }
  }

  public onSelect(selected) {
    this.service.deleteSingleData(selected);
  }

  public updateData(type, selectedId) {
    let selectedRecord = this.data.filter((item) => item.id == selectedId)[0];
    this.iconClick.emit({ eventType: type, data: selectedRecord });
  }
  public viewData(type, selectedId) {
    let selectedRecord = this.data.filter((item) => item.id == selectedId)[0];
    this.iconClick.emit({ eventType: type, data: selectedRecord });
  }
  public deleteData(type, selectedId) {
    let selectedRecord = this.data.filter((item) => item.id == selectedId)[0];
    this.iconClick.emit({ eventType: type, data: selectedRecord });
  }

  ngOnInit() {
    this.service.setUserData(this.data);
    this.tableItem$ = this.service.tableItem$;
    this.total$ = this.service.total$;
  }
}
