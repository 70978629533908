import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { IUserDetailModel } from "src/app/shared/model/proseeker/user-detail.model";
import { ICreateStaffUserRequestModel } from "../../../../shared/model/proseeker/staff.model";
import { HttpRespnseType, StaffService } from "../staff.service";
import { ToastService } from "../../../../shared/components/toast/toast.service";
import {
  Lookup_UserType,
  Lookup_ProfileStatus,
} from "../../../../shared/enums/Lookups";

@Component({
  selector: "app-create-staff-form",
  templateUrl: "./create-staff-form.component.html",
  styleUrls: ["./create-staff-form.component.scss"],
})
export class CreateStaffFormComponent implements OnInit {
  createStaffForm: FormGroup;
  @Input() userData: any;
  @Output() dataUpdated = new EventEmitter<void>();
  constructor(
    protected activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private staffService: StaffService,
    private toastrService: ToastService
  ) {}

  ngOnInit(): void {
    this.createStaffForm = this.fb.group({
      title: [this.userData != null ? this.userData.userDetail.title : ""],
      firstName: [
        this.userData != null ? this.userData.userDetail.firstName : "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z][a-zA-Z ]+[a-zA-Z]$"),
          Validators.minLength(3),
        ],
      ],
      lastName: [
        this.userData != null ? this.userData.userDetail.lastName : "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z][a-zA-Z ]+[a-zA-Z]$"),
          Validators.minLength(3),
        ],
      ],
      userName: [
        this.userData != null ? this.userData.userDetail.username : "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9][a-zA-Z0-9 ]*[a-zA-Z0-9]$"),

          Validators.minLength(3),
          Validators.maxLength(10),
        ],
      ],
      password: ["", Validators.required],
      gender: [
        this.userData != null ? this.userData.userDetail.gender : "",
        [Validators.required],
      ],
      staffType: [
        this.userData != null ? this.userData.userDetail.staffType : "",
        [Validators.required],
      ],
    });
  }

  cancel(): void {
    this.activeModal.close();
  }

  submit() {
    if (this.createStaffForm.invalid) {
      return this.createStaffForm.markAllAsTouched();
    }

    let userDetails: IUserDetailModel = {
      title: this.createStaffForm.controls["title"].getRawValue(),
      firstName: this.createStaffForm.controls["firstName"].getRawValue(),
      lastName: this.createStaffForm.controls["lastName"].getRawValue(),
      gender: this.createStaffForm.controls["gender"].getRawValue(),
      username: this.createStaffForm.controls["userName"].getRawValue(),
      userType: Lookup_UserType.STAFF,
      profileStatus: Lookup_ProfileStatus.ACTIVE,
    };

    let createRequest: ICreateStaffUserRequestModel = {
      userDetail: userDetails,
      password: this.createStaffForm.controls["password"].getRawValue(),
      staffType: this.createStaffForm.controls["staffType"].getRawValue(),
    };

    this.subscribeToSaveResponse(
      this.staffService.create(this.userData != null, createRequest)
    );

    // this.activeModal.close();
  }

  subscribeToSaveResponse(result: Observable<HttpRespnseType>): void {
    result.subscribe({
      next: () => this.onSaveSuccess(),
      error: (res) => this.onSaveError(res),
    });
  }

  protected onSaveSuccess(): void {
    this.activeModal.close();
    this.toastrService.success("", "Staff member added successfully");

  }

  protected onSaveError(res): void {
    this.activeModal.close();
    //  this.toastrService.error("", res.error.message);
  }
}
