import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PrimeNGConfig } from "primeng/api";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Proseeker";

  constructor(
    private primengConfig: PrimeNGConfig,
    protected readonly router: Router
  ) {}

  ngOnInit() {
    this.router.navigate(["/dashboard/admin"]);
    this.primengConfig.ripple = true;
  }
}
