import { transition, trigger, useAnimation } from "@angular/animations";
import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as feather from "feather-icons";
import { fadeIn } from "ng-animate";
import { AuthService } from "src/app/core/auth/service/auth.service";
import { Roles } from "src/app/shared/enums/roles";
import { LookupMap } from "src/app/shared/lookup/lookup-map";
import { LookupService } from "src/app/shared/services/lookup/lookup.service";
import { CustomizerService } from "../../../services/customizer.service";
import { NavService } from "../../../services/nav.service";

@Component({
  selector: "app-content-layout",
  templateUrl: "./content-layout.component.html",
  styleUrls: ["./content-layout.component.scss"],
  animations: [
    trigger("animateRoute", [
      transition(
        "* => *",
        useAnimation(fadeIn, {
          // Set the duration to 5seconds and delay to 2 seconds
          //params: { timing: 3}
        })
      ),
    ]),
  ],
})
export class ContentLayoutComponent implements OnInit, AfterViewInit {
  public right_side_bar: boolean;

  constructor(
    public navServices: NavService,
    public customizer: CustomizerService,
    protected readonly router: Router,
    protected readonly authService: AuthService,
    protected readonly lookupService: LookupService
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  @HostListener("document:click", ["$event"])
  clickedOutside(event) {
    // click outside Area perform following action
    document.getElementById("outer-container").onclick = function (e) {
      e.stopPropagation();
      if (e.target != document.getElementById("search-outer")) {
        document.getElementsByTagName("body")[0].classList.remove("offcanvas");
      }
      if (e.target != document.getElementById("outer-container")) {
        document
          .getElementById("canvas-bookmark")
          .classList.remove("offcanvas-bookmark");
      }
      if (e.target != document.getElementById("inner-customizer")) {
        document
          .getElementsByClassName("customizer-links")[0]
          .classList.remove("open");
        document
          .getElementsByClassName("customizer-contain")[0]
          .classList.remove("open");
      }
    };
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : "dashboard";
  }

  public rightSidebar($event) {
    this.right_side_bar = $event;
  }

  ngOnInit() {
    this.lookupService.getAllLookupNames().subscribe((data) => {
      data.body.forEach((lookup) => {
        LookupMap.getLookupNamesMap().set(lookup.id, lookup.name);
        this.lookupService.getLookup(lookup.id).subscribe({
          next: (data) => {
            LookupMap.addLookupToMap(lookup.name, {
              lookupData: data.body.lookupData,
            });
          },
        });
      });
    });
    console.log("this.authService.getRoles() ", this.authService.getRoles());

    if (this.authService.getRoles().includes(Roles.ROLE_BO_ADMIN)) {
      console.log("routing to admin dashboard");
      this.router.navigate(["/dashboard/admin"]);
    } else if (
      this.authService.getRoles().includes(Roles.ROLE_BO_STAFF_FINANCE_MANAGER)
    ) {
      console.log("routing to staff dashboard");
      this.router.navigate(["/dashboard/staff"]);
    }
  }
}
