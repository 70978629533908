import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-delete-dialog",
  templateUrl: "./delete-dialog.component.html",
  styleUrls: ["./delete-dialog.component.scss"],
})
export class DeleteDialogComponent implements OnInit {
  @Output() confirmDelete = new EventEmitter<boolean>();
  @Input() data: any;

  constructor(protected modalService: NgbModal) {}

  ngOnInit(): void {}
  cancel() {
    this.modalService.dismissAll();
  }

  confirm() {
    this.confirmDelete.emit(true);
    this.modalService.dismissAll();
  }
}
