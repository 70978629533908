import {
  ILookupCodeToTextModel,
  ILookupDataModel,
} from '../model/proseeker/lookup/lookup.model';

export class LookupMap {
  private static lookupMap: Map<string, ILookupCodeToTextModel>;
  private static lookupNamesMap: Map<number, string>;

  public static getLookupMap(): Map<string, ILookupCodeToTextModel> {
    if (!LookupMap.lookupMap) {
      LookupMap.lookupMap = new Map<string, ILookupCodeToTextModel>();
    }

    return LookupMap.lookupMap;
  }

  public static getLookupNamesMap(): Map<number, string> {
    if (!LookupMap.lookupNamesMap) {
      LookupMap.lookupNamesMap = new Map<number, string>();
    }

    return LookupMap.lookupNamesMap;
  }

  public static addLookupToMap(
    lookupName: string,
    value: ILookupCodeToTextModel
  ) {
    console.log(
      'Adding data to lookupMap=>(' + lookupName + '=>' + value + ')'
    );
    this.getLookupMap().set(lookupName, value);
  }
}
