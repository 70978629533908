<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-xl-12">
      <jhi-alert-error></jhi-alert-error>
      <div class="row">
        <div class="col-sm-12">
          <form
            class="theme-form"
            novalidate
            [formGroup]="createUserForm"
            (ngSubmit)="submit()"
          >
            <div class="card">
              <div class="card-header">
                <h5>Add new user member</h5>
              </div>
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label"
                    >First Name
                    <span class="required-asterisk">*</span>
                  </label>
                  <div class="col-sm-9">
                    <input
                      class="form-control"
                      type="text"
                      name="firstName"
                      data-cy="firstName"
                      [formControl]="createUserForm.controls['firstName']"
                      required
                    />

                    <div
                      *ngIf="
                        createUserForm.controls.firstName.touched &&
                        createUserForm.controls.firstName.errors?.required
                      "
                      class="text text-danger"
                      data-cy="firstName-error"
                    >
                      First Name is required.
                    </div>
                    <div
                      *ngIf="
                        createUserForm.controls.firstName.touched &&
                        createUserForm.controls.firstName.errors?.pattern
                      "
                      class="text text-danger"
                      data-cy="firstName-alphabates-error"
                    >
                      Only alphabets are allowed.
                    </div>
                    <div
                      *ngIf="
                        createUserForm.controls.firstName.touched &&
                        createUserForm.controls.firstName.errors?.minlength
                      "
                      class="text text-danger"
                      data-cy="firstName-short-error"
                    >
                      Length should be at least 3 alphabets.
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label"
                    >Last Name
                    <span class="required-asterisk">*</span>
                  </label>
                  <div class="col-sm-9">
                    <input
                      class="form-control"
                      type="text"
                      name="lastName"
                      data-cy="lastName"
                      [formControl]="createUserForm.controls['lastName']"
                      required
                    />

                    <div
                      *ngIf="
                        createUserForm.controls.lastName.touched &&
                        createUserForm.controls.lastName.errors?.required
                      "
                      class="text text-danger"
                      data-cy="lastName-error"
                    >
                      Last Name is required.
                    </div>
                    <div
                      *ngIf="
                        createUserForm.controls.lastName.touched &&
                        createUserForm.controls.lastName.errors?.pattern
                      "
                      class="text text-danger"
                      data-cy="lastName-alphabates-error"
                    >
                      Only alphabets are allowed
                    </div>
                    <div
                      *ngIf="
                        createUserForm.controls.lastName.touched &&
                        createUserForm.controls.lastName.errors?.minlength
                      "
                      class="text text-danger"
                      data-cy="lastName-short-error"
                    >
                      Length should be at least 3 alphabets
                    </div>
                  </div>
                </div>
                <fieldset class="form-group">
                  <div class="row">
                    <label class="col-form-label col-sm-3 pt-0" data-cy="gender"
                      >Gender
                      <span class="required-asterisk">*</span>
                    </label>
                    <div class="col-sm-9">
                      <div class="radio radio-primary ms-2">
                        <input
                          id="gender_male"
                          type="radio"
                          name="gender"
                          data-cy="male"
                          value="1"
                          [formControl]="createUserForm.controls['gender']"
                        />
                        <label for="gender_male" class="m-b-10">Male</label>
                      </div>
                      <div class="radio radio-primary ms-2">
                        <input
                          id="gender_female"
                          type="radio"
                          name="gender"
                          data-cy="female"
                          value="2"
                          [formControl]="createUserForm.controls['gender']"
                        />
                        <label for="gender_female" class="m-b-10">Female</label>
                      </div>
                      <div
                        *ngIf="
                          createUserForm.controls.gender.touched &&
                          createUserForm.controls.gender.errors?.required
                        "
                        class="text text-danger"
                        data-cy="gender-error"
                      >
                        Gender is required.
                      </div>
                    </div>
                  </div>
                </fieldset>

                <fieldset class="form-group">
                  <div class="form-group row">
                    <label
                      for="userType"
                      class="col-sm-3 col-form-label"
                      data-cy="userType"
                      >User Type
                      <span class="required-asterisk">*</span>
                    </label>
                    <div class="col-sm-9">
                      <select
                        name="userType"
                        id="userType"
                        class="userType"
                        data-cy="userType"
                        [formControl]="createUserForm.controls['userType']"
                        required
                      >
                        <option
                          value=""
                          disabled
                          selected
                          data-cy="select-User-Type"
                        >
                          Select User Type
                        </option>
                        <option value="1" data-cy="seeker">Seeker</option>
                        <option value="2" data-cy="provider">Provider</option>
                      </select>
                      <div
                        *ngIf="
                          createUserForm.controls.userType.touched &&
                          createUserForm.controls.userType.errors?.required
                        "
                        class="text text-danger"
                        data-cy="userType-error"
                      >
                        UserType is required.
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div class="form-group row">
                  <label
                    for="dateOfBirth"
                    class="col-sm-3 col-form-label"
                    data-cy="dateOfBirth"
                    >Date of Birth
                    <span class="required-asterisk">*</span>
                  </label>
                  <div class="col-sm-9">
                    <input
                      id="dateOfBirth"
                      data-cy="dateOfBirth"
                      class="form-control"
                      placeholder="yyyy-mm-dd"
                      name="dp"
                      ngbDatepicker
                      #dp="ngbDatepicker"
                      [formControl]="createUserForm.controls['dateOfBirth']"
                      required
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-secondary calendar"
                        data-cy="btn-calender"
                        (click)="dp.toggle()"
                        type="button"
                      >
                        <i class="icon-calendar"></i>
                      </button>
                    </div>
                    <div
                      *ngIf="
                        createUserForm.controls.dateOfBirth.touched &&
                        createUserForm.controls.dateOfBirth.errors?.required
                      "
                      class="text text-danger"
                      data-cy="dateOfBirth-error"
                    >
                      Date of Birth is required.
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label" data-cy="userName"
                    >Username
                    <span class="required-asterisk">*</span>
                  </label>
                  <div class="col-sm-9">
                    <input
                      class="form-control"
                      type="text"
                      name="userName"
                      data-cy="userName"
                      [formControl]="createUserForm.controls['userName']"
                      required
                    />
                    <div
                      *ngIf="
                        createUserForm.controls.userName.touched &&
                        createUserForm.controls.userName.errors?.required
                      "
                      class="text text-danger"
                      data-cy="Username-error"
                    >
                      Username is required.
                    </div>

                    <div
                      *ngIf="
                        createUserForm.controls.userName.touched &&
                        createUserForm.controls.userName.errors?.pattern
                      "
                      class="text text-danger"
                      data-cy="Username-alphabates-error"
                    >
                      Alphabets and numbers are allowed
                    </div>
                    <div
                      *ngIf="
                        createUserForm.controls.userName.touched &&
                        createUserForm.controls.userName.errors?.minlength
                      "
                      class="text text-danger"
                      data-cy="Username-short-error"
                    >
                      Length should be at least 3 alphabets.
                    </div>
                    <div
                      *ngIf="
                        createUserForm.controls.userName.touched &&
                        createUserForm.controls.userName.errors?.maxlength
                      "
                      class="text text-danger"
                      data-cy="Username-long-error"
                    >
                      Username length should be less than 10.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label" data-cy="password"
                    >Password
                    <span class="required-asterisk">*</span>
                  </label>
                  <div class="col-sm-9">
                    <input
                      class="form-control"
                      name="password"
                      type="password"
                      data-cy="password"
                      [formControl]="createUserForm.controls['password']"
                    />

                    <div
                      *ngIf="
                        createUserForm.controls.password.touched &&
                        createUserForm.controls.password.errors?.required
                      "
                      class="text text-danger"
                      data-cy="password-error"
                    >
                      Password is required.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    for="profileStatus"
                    class="col-sm-3 col-form-label"
                    data-cy="profileStatus"
                    >Profile Status
                    <span class="required-asterisk">*</span>
                  </label>
                  <div class="col-sm-9">
                    <select
                      name="profileStatus"
                      id="profileStatus"
                      class="profileStatus"
                      data-cy="profileStatus"
                      [formControl]="createUserForm.controls['profileStatus']"
                      required
                    >
                      <option
                        value=" "
                        disabled
                        selected
                        data-cy="selectStatus"
                      >
                        Select Status
                      </option>
                      <option value="1" data-cy="activeUser">
                        Active User
                      </option>
                      <option value="2" data-cy="inactiveUser">
                        Inactive User
                      </option>
                    </select>
                    <div
                      *ngIf="
                        createUserForm.controls.profileStatus.touched &&
                        createUserForm.controls.profileStatus.errors?.required
                      "
                      class="text text-danger"
                      data-cy="profileStatus-error"
                    >
                      Profile Status is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  class="btn btn-primary me-1"
                  btn-id="submit"
                  type="submit"
                >
                  Submit
                </button>
                <button
                  class="btn btn-secondary"
                  btn-id="cancel"
                  (click)="cancel()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
