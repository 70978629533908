import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { KeycloakRoles } from 'keycloak-js';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    // var userRoles: KeycloakRoles =
    //   this.keycloakAngular.getKeycloakInstance().tokenParsed['realm_access'];

    // var userInfo;

    // await this.keycloakAngular
    //   .getKeycloakInstance()
    //   .loadUserInfo()
    //   .then((info) => {
    //     userInfo = info;
    //     console.log('name:' + info['name']);
    //   });

    // console.log(
    //   this.keycloakAngular.getKeycloakInstance().tokenParsed['realm_access']
    // );
    // console.log('userInfo => ' + JSON.stringify(userInfo));

    // console.log(this.keycloak.getUserRoles());

    // console.log(this.keycloakAngular.getKeycloakInstance().loadUserProfile());

    // Get the roles required from the route.
    const requiredRoles = route.data['roles'];

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }
}
