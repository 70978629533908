import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../core/config/application-config.service';
import {
  ILookupModel,
  ILookupNameModel,
} from '../../model/proseeker/lookup/lookup.model';
import { Observable } from 'rxjs';

export type EntityResponseType = HttpResponse<ILookupModel>;

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  protected resourceUrl =
    this.applicationConfigService.getStaticDataServiceEndpoint('api/lookups');

  constructor(
    protected http: HttpClient,
    protected applicationConfigService: ApplicationConfigService
  ) {}

  getAllLookupNames(): Observable<HttpResponse<ILookupNameModel[]>> {
    return this.http.get<ILookupNameModel[]>(`${this.resourceUrl}`, {
      observe: 'response',
    });
  }

  getLookup(id: number): Observable<EntityResponseType> {
    return this.http.get<ILookupModel>(`${this.resourceUrl}/${id}`, {
      observe: 'response',
    });
  }
}
