<div class="card">
  <app-create-user-button
    [userData]="userUserData"
    (modalClosed)="clearData()"
  ></app-create-user-button>
  <aq-table
    [data]="userData"
    [headers]="tableHeader"
    [title]="title"
    (iconClick)="handleIconClickEvent($event)"
  >
  </aq-table>
</div>
