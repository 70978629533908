<div class="main-header-left d-none d-lg-block">
  <div class="logo-wrapper"><a href="javascript:void(0)"><img src="assets/images/Proseeker-logo.png" alt=""></a></div>
</div>
<div class="sidebar custom-scrollbar">
  <div class="sidebar-user text-center">
    <div><img class="img-60 rounded-circle" src="assets/images/user/1.jpg" alt="#">
      <div class="profile-edit"><a>
          <i class="icofont icofont-pencil-alt-5"><input class="upload" type="file" (change)="readUrl($event)" /></i>
        </a></div>
    </div>
    <h6 class="mt-3 f-14">{{loggedInUserFullName}}</h6>
    <p></p>
  </div>
  <ul class="sidebar-menu">
    <li *ngFor="let menuItem of menuItems" [ngClass]="{active: menuItem.active}">
      <!-- Link -->
      <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active" class="sidebar-header"
      [attr.data-cy]="menuItem.testSelector" *ngIf="menuItem.type === 'link'">
        <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title| translate}}<span
            class="badge badge-{{menuItem.badgeType}} me-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>
    </li>
  </ul>
</div>