<button
  class="btn btn-secondary float-right"
  type="button"
  placement="top"
  (click)="createStaffFormModal()"
  data-cy="button-add-staff"
  dataUpdated
>
  Add Staff
</button>
