import { Component, OnInit } from "@angular/core";
import { UserService } from "../user.service";

@Component({
  templateUrl: "./manage-user.component.html",
  styleUrls: ["./manage-user.component.scss"],
})
export class ManageUserComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
