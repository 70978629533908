<div
  class="modal-content"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-header">
    <h5 class="modal-title">Do you really want to delete this ?</h5>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <p>This record will be deleted permanently. Continue ?</p>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary me-1"
        data-cy="btn-cancel-user"
        data-dismiss="modal"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button type="button" class="btn btn-danger" (click)="confirm()">
        Confirm
      </button>
    </div>
  </div>
</div>
