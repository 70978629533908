import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  EventManager,
  EventWithContent,
} from '../../shared/services/alert/event-manager.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private eventManager: EventManager) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          console.log('error intercepted: ' + err.status);
          if (
            !(
              err.status === 401 &&
              (err.message === '' || err.url?.includes('api/account'))
            )
          ) {
            console.log('error intercepted from inside: ' + err.status);
            this.eventManager.broadcast(
              new EventWithContent('proseeker.httpError', err)
            );
          }
        },
      })
    );
  }
}
