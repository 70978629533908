<div
  class="modal-content"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-header">
    <h5 class="modal-title">View Staff Data!</h5>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <label class="col-modal-label">First Name: </label>
    </div>
    <div></div>
    <div>
      <label class="col-modal-label">Last Name: </label>
    </div>
    <div></div>
    <div>
      <label class="col-modal-label">Gender: </label>
    </div>
    <div></div>
    <div>
      <label class="col-modal-label">User Type: </label>
    </div>
    <div></div>
    <div>
      <label class="col-modal-label">Date of Birth: </label>
    </div>
    <div></div>
    <div>
      <label class="col-modal-label">Username: </label>
    </div>
    <div></div>
    <div>
      <label class="col-modal-label">Password: </label>
    </div>
    <div></div>
    <div>
      <label class="col-modal-label">Profile Status: </label>
    </div>
    <div></div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      btn-id="close"
      (click)="close()"
    >
      Close
    </button>
  </div>
</div>
